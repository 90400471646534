<template>
  <div id="main" style="height: 400px"></div>
</template>
<script>
import * as echarts from 'echarts'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnBtcDataTable',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    color: {
      type: Object,
      default: () => {}
    },
    legend: {
      type: Object,
      default: () => ({
        icon: 'roundRect',
        itemWidth: 10,
        itemHeight: 10,
        left: 150,
        top: 15,
        selectedMode: false
      })
    },
    title: {
      type: String,
      default: ''
    },
    yAxisName: {
      type: String,
      default: '元/公斤'
    },
    isEnExpire: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      myChart: null,
      option: {
        title: {
          text: this.title,
          left: 'center'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '14%',
          containLabel: true
        },
        animation: false,
        tooltip: {
          show: this.$store.getters.token,
          trigger: 'axis'
        },
        legend: this.legend,
        xAxis: {
          type: 'category',
          data: this.data.categories
        },
        yAxis: {
          name: this.yAxisName,
          type: 'value'
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100
        }, {
          start: 0,
          end: 100,
          height: 15,
          bottom: 20
        }],
        series: this.getSeries(this.data.series)
      }
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    chartDom () {
      return document.getElementById('main')
    }
  },
  methods: {
    init () {
      this.myChart = echarts.init(this.chartDom)
      this.myChart.setOption(this.option)

      const zr = this.myChart.getZr()
      zr.on('click', (params) => {
        const pointInPixel = [params.offsetX, params.offsetY]
        const pointInGrid = this.myChart.convertFromPixel('grid', pointInPixel)
        if (this.myChart.containPixel('grid', pointInPixel)) {
          const chartX = this.myChart.getOption().xAxis[0].data[pointInGrid[0]]
          if (this.$store.getters.token) {
            if (this.isEnExpire) {
              this.$emit('getChartX', chartX)
            } else {
              this.openCnMembershipModal()
            }
          } else {
            this.openCnLoginModal()
          }
        }
      })
    },
    getSeries (data) {
      // 长度等于1，区域图
      if (data.length === 1) {
        return data.map(v => {
          v.itemStyle = {}
          v.areaStyle = {}
          v.symbol = 'none'
          const colorArr = this.getColor(v.name)
          if (colorArr.length > 0) {
            v.itemStyle.color = colorArr[0].Color1
            v.areaStyle.color = this.getAreaColor(colorArr[0])
          } else if (this.color && JSON.stringify(this.color) !== '{}') {
            v.itemStyle.color = this.color.Color1
            v.areaStyle.color = this.getAreaColor(this.color)
          }
          return v
        })
      } else {
        // 长度大于1，折线图
        return data.map(v => {
          v.itemStyle = {}
          v.symbol = 'none'
          const colorArr = this.getColor(v.name)
          if (colorArr.length > 0) {
            v.itemStyle.color = colorArr[0].Color1
          }
          return v
        })
      }
    },
    getColor (origin) {
      const colors = [
        { Origin: 'Argentina', Color1: '#4BBEE7', Color2: '#FFFFFF' },
        { Origin: 'Australia', Color1: '#F65B5C', Color2: '#FFFFFF' },
        { Origin: 'Uruguay', Color1: '#E1DC45', Color2: '#FFFFFF' },
        { Origin: 'Brazil', Color1: '#94DC71', Color2: '#FFFFFF' },
        { Origin: 'New Zealand', Color1: '#5F77D7', Color2: '#FFFFFF' }
      ]
      return colors.filter(v => {
        return v.Origin === origin
      })
    },
    getAreaColor (colors) {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: colors.Color1
      }, {
        offset: 1,
        color: colors.Color2
      }])
    },
    refreshData (data) {
      const opt = this.myChart.getOption()
      opt.series = this.getSeries(data.series)
      opt.xAxis = {
        type: 'category',
        data: data.categories
      }
      opt.yAxis = {
        name: this.yAxisName,
        type: 'value'
      }
      opt.title = {
        text: this.title,
        left: 'center'
      }
      this.myChart.setOption(opt, true)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
