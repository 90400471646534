<template>
  <div>
    <div class="item-box">
      <btc-table v-if="list" :is-en-expire="isEnExpire" :is-price-login="isLogin" :tableList="list" :feedingMode="listQuery.FeedingMode" :items="header" :isSwitchNavBar.sync="isSwitchNavBar" :defaultDate="defaultDate" @handlePicker="handlePicker" @handleDate="handleDate"/>
    </div>
  </div>
</template>

<script>
import BtcTable from './components/table/market-price-spot'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'
// import dict from '@/utils/dict.js'

export default {
  mixins: [commonmix],
  name: 'CnExportsToChina',
  components: {
    BtcTable
    // dict
  },
  data () {
    return {
      listCountry: [],
      listQuery: {
        UserID: getLocalStore().UserID,
        ReportID: 867,
        PreReportID: 862,
        OriginPlace: '全部',
        Part: '全部',
        FeedingMode: '草饲',
        Type: 1
      },
      dateListQuery: {
        ReportType: '_spotgoods',
        LanguageCode: 'zh-CN',
        Type: 1
      },
      header: [
        { label: '国别', icon: require('@/assets/image/price/success/porkcountry.png') },
        { label: '部位', icon: require('@/assets/image/price/success/porkcuts.png') },
        { label: '元/公斤', icon: require('@/assets/image/price/success/porkrmb.png') },
        { label: '参考厂号', icon: require('@/assets/image/price/success/porknotes.png') },
        { label: '走势', icon: require('@/assets/image/price/success/porktrend.png') }
      ],
      isSwitchNavBar: false,
      list: null,
      dateList: null,
      defaultDate: null
    }
  },
  computed: {
    isLogin () {
      return this.$store.getters.token == null || this.$store.getters.token === ''
    },
    isEnExpire () {
      return this.getUserProfile(this.$t('message._beefpriceindex'))
    }
  },
  mounted () {
    this.getDateData()
  },
  methods: {
    // 获取到查询的时间
    getDateData () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPriceVaildDate', this.dateListQuery).then(() => {
        this.dateList = this.$store.getters.priceDate

        // 设置数据查询的时间
        this.listQuery.ReportID = this.dateList[0].ReportID
        this.listQuery.PreReportID = this.dateList[1].ReportID
        this.defaultDate = this.dateList.map(v => {
          return v.ReportDateTime
        })
        this.$store.dispatch('SetLoadingStatus', false)
        this.getData()
      }).catch((err) => {
        this.$store.dispatch('SetLoadingStatus', false)
        console.error(err)
      })
    },
    getData () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPriceDataList', this.listQuery).then(() => {
        this.list = this.$store.getters.priceRows
        if (this.listQuery.OriginPlace === '全部' && this.listQuery.Part === '全部') {
          this.$store.commit('SET_COUNTRY', this.list)
        }
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    handleNavBar (item) {
      this.listQuery.OriginPlace = '全部'
      this.listQuery.Part = '全部'

      this.isSwitchNavBar = true
      this.getData()
    },
    handlePicker (item) {
      this.listQuery.OriginPlace = item.OriginPlace
      this.listQuery.Part = item.Part
      this.getData()
    },
    handleDate (item) {
      this.dateList.filter((v, index) => {
        if (v.ReportDateTime === item) {
          const index1 = index + 1
          this.listQuery.ReportID = this.dateList[index].ReportID
          this.listQuery.PreReportID = this.dateList[index1].ReportID
        }
      })
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  height: 968px;
  margin: 0 20px 20px 20px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

/* 筛选 */
.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
</style>
