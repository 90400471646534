<template>
  <div>
    <div class="selector-box">
      <div class="selector-left">
        <multi-select
          name="国别"
          :is-show="isCountryShow"
          :is-radio="isCountryRadio"
          :default-feed="defaultFeed"
          :default-type="type.value"
          :data="countryList"
          @handleCheckbox="handleCheckbox($event,1)"
          @handleShow="handleShow(1)"
          @handleOk="handleOk(1)"/>
        <multi-select
          name="部位"
          mode="two"
          :is-show="isCutsShow"
          :is-radio="isCutsRadio"
          :default-feed="defaultFeed"
          :default-type="type.value"
          :data="cutsList"
          :check-item="cutsCheckItems"
          @handleCheckbox="handleCheckbox($event,2)"
          @handleShow="handleShow(2)"
          @handleOk="handleOk(2)"/>
      </div>
    </div>
    <div class="header">
      <div v-for="(item, index) in items" :key="index">
        <img :src="item.icon">
        <span :class="{ 'ml-10': item.icon }">{{ item.label }}</span>
      </div>
    </div>
    <div class="table-box">
      <div v-for="(item, index) in tableList" :key="index" class="table-item">
        <span>{{ item.country}}</span>
        <span>{{ item.cuts }}</span>
        <span>{{ item.date }}</span>
        <span>{{ item.money }}</span>
        <span>{{ item.rise }}</span>
      </div>
    </div>
  </div>
</template>
<script>
// import Selector from './components/selector'
import MultiSelect from './components/multi-select'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnBtcDataTable',
  components: {
    MultiSelect
  },
  props: {
    tableList: {
      type: Array,
      default: () => []
    },
    countryList: {
      type: Array,
      default: () => []
    },
    cutsList: {
      type: Array,
      default: () => []
    },
    type: {
      type: Object,
      default: () => {}
    },
    closeSelect: {
      type: Boolean,
      default: false
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items () {
      if (this.type.value === '_spotgoods') {
        return [
          { label: '国别', icon: require('@/assets/image/price/success/porkcountry.png') },
          { label: '部位', icon: require('@/assets/image/price/success/porkcuts.png') },
          { label: '时间', icon: require('@/assets/image/price/Date.png') },
          { label: '元/公斤', icon: require('@/assets/image/price/success/porkrmb.png') },
          { label: '涨跌', icon: require('@/assets/image/price/success/porktrend.png') }
        ]
      } else {
        return [
          { label: '产地', icon: require('@/assets/image/price/Country.png') },
          { label: '部位', icon: require('@/assets/image/price/Cuts.png') },
          { label: '时间', icon: require('@/assets/image/price/Date.png') },
          { label: '美元/吨', icon: require('@/assets/image/price/USD.png') },
          { label: '涨跌', icon: require('@/assets/image/price/Trend.png') }
        ]
      }
    },
    defaultTypeVisible: {
      get () {
        return this.type
      },
      set (val) {
        this.$emit('update:type', val)
      }
    },
    closeSelectVisible: {
      get () {
        return this.closeSelect
      },
      set (val) {
        this.$emit('update:closeSelect', val)
      }
    }
  },
  watch: {
    defaultTypeVisible (val) {
      this.countryCheckItems = ['西班牙']
      this.cutsCheckItems = ['西班牙']
    },
    closeSelectVisible (val) {
      if (val) {
        this.isCountryShow = false
        this.isCutsShow = false

        this.closeSelectVisible = false
      }
    }
  },
  data () {
    return {
      countryCheckItems: ['西班牙'],
      cutsCheckItems: ['西班牙'],
      defaultFeed: '草饲',
      isCountryShow: false,
      isCutsShow: false,
      isShow: false,
      isCountryRadio: false,
      isCutsRadio: false
    }
  },
  methods: {
    // 切换草谷饲
    // handleFeedingMode (types) {
    //   this.defaultFeed = types.value
    //   this.countryCheckItems = types.value === '草饲' ? ['巴西'] : ['澳大利亚']
    //   this.cutsCheckItems = types.value === '草饲' ? ['牛腩80vl'] : ['板腱']

    //   this.$emit('handleFeedingMode', {
    //     Countries: this.countryCheckItems,
    //     Parts: this.cutsCheckItems,
    //     FeedingMode: types.value
    //   })
    // },
    // 控制开关
    handleShow (val) {
      if (val === 1) {
        this.isCountryShow = !this.isCountryShow
        if (this.isCutsShow) {
          this.isCutsShow = false
        }
      } else if (val === 2) {
        this.isCutsShow = !this.isCutsShow
        if (this.isCountryShow) {
          this.isCountryShow = false
        }
      }
    },
    // 控制数据单多选
    handleCheckbox (item, val) {
      if (val === 1) {
        this.countryCheckItems = item
        // 点击国家后重新选择部位
        this.cutsCheckItems = []
        if (item.length > 1) {
          this.isCutsRadio = true
        } else {
          this.isCutsRadio = false
        }
        this.$emit('handleCountryItems', item)
      } else if (val === 2) {
        this.cutsCheckItems = item
        this.isCountryRadio = false
      }
    },
    // 点击Ok按钮
    handleOk (val) {
      if (val === 1) {
        this.isCountryShow = false
        this.isCutsShow = true
      } else if (val === 2) {
        if (this.$store.getters.token) {
          if (this.isEnExpire) {
            if (this.cutsCheckItems.length) {
              this.isCutsShow = false
              this.$emit('handleCuts', {
                Parts: this.cutsCheckItems,
                Countries: this.countryCheckItems
              })
            }
          } else {
            this.openCnMembershipModal()
          }
        } else {
          this.openCnLoginModal()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$cell-width: 150px;

.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
/* 表头 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

.ml-10 {
  margin-left: 10px;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.table-box {
  margin-top: 10px;
  height: 400px;
  overflow-y: scroll;
}

.table-item {
  height: 35px;
  border-radius: 8px;
  background: #f1f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.table-item > span {
  width: $cell-width;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
</style>
