import store from '@/store'

// 获取TableHeader
export function handleTableHeader (feedingMode, type) {
  switch (type) {
    case '_market_price':
      switch (feedingMode) {
        case '草饲':
          return [
            { label: '产地', icon: require('@/assets/image/price/success/country.png') },
            { label: '部位', icon: require('@/assets/image/price/success/cuts.png') },
            { label: '元/公斤', icon: require('@/assets/image/price/success/rmb.png') },
            { label: '等级', icon: require('@/assets/image/price/success/notes.png') },
            { label: '走势', icon: require('@/assets/image/price/success/trend.png') }
          ]
        case '谷饲':
          return [
            { label: '产地', icon: require('@/assets/image/price/warning/country.png') },
            { label: '部位', icon: require('@/assets/image/price/warning/cuts.png') },
            { label: '元/公斤', icon: require('@/assets/image/price/warning/rmb.png') },
            { label: '备注', icon: require('@/assets/image/price/warning/notes.png') },
            { label: '走势', icon: require('@/assets/image/price/warning/trend.png') }
          ]
        default:
          return [
            { label: '产地', icon: require('@/assets/image/price/danger/country.png') },
            { label: '部位', icon: require('@/assets/image/price/danger/cuts.png') },
            { label: '美元/吨', icon: require('@/assets/image/price/danger/rmb.png') },
            { label: '走势', icon: require('@/assets/image/price/danger/trend.png') }
          ]
      }
    case '_ups_downs':
      switch (feedingMode) {
        case '草饲':
          return [
            { label: '国别', icon: require('@/assets/image/price/warning/country.png') },
            { label: '部位', icon: require('@/assets/image/price/warning/cuts.png') },
            { label: '元/公斤', icon: require('@/assets/image/price/warning/rmb.png') },
            { label: '备注', icon: require('@/assets/image/price/warning/notes.png') },
            { label: '涨跌', icon: require('@/assets/image/price/warning/change.png') },
            { label: '走势', icon: require('@/assets/image/price/warning/trend.png') }
          ]
        case '谷饲':
          return [
            { label: '产地', icon: require('@/assets/image/price/warning/country.png') },
            { label: '部位', icon: require('@/assets/image/price/warning/cuts.png') },
            { label: '元/公斤', icon: require('@/assets/image/price/warning/rmb.png') },
            { label: '等级', icon: require('@/assets/image/price/warning/notes.png') },
            { label: '涨跌', icon: require('@/assets/image/price/warning/change.png') },
            { label: '走势', icon: require('@/assets/image/price/warning/trend.png') }
          ]
        default:
          return [
            { label: '产地', icon: require('@/assets/image/price/danger/country.png') },
            { label: '部位', icon: require('@/assets/image/price/danger/cuts.png') },
            { label: '美元/吨', icon: require('@/assets/image/price/danger/rmb.png') },
            { label: '涨跌', icon: require('@/assets/image/price/danger/change.png') },
            { label: '走势', icon: require('@/assets/image/price/danger/trend.png') }
          ]
      }
  }
}

export function getSeries (data, type = false) {
  const arr = []
  if (type) {
    data.forEach(function (item) {
      arr.push({ name: item.name2 + ' - ' + item.name, data: item.data, type: 'line' })
    })
  } else {
    data.forEach(function (item) {
      arr.push({ name: item.name + ' - ' + item.name2, data: item.data, type: 'line' })
    })
  }
  return arr
}

export function getCountry () {
  const country = store.getters.priceCountry.map((item, index) => {
    return item.OriginPlace
  })
  const uniqueCountry = unique(country)
  uniqueCountry.splice(0, 1, '全部')
  return uniqueCountry
}

export function getCuts (defaultCountry) {
  if (defaultCountry === '全部') {
    return ['全部']
  } else {
    const cuts = store.getters.priceCountry.filter(v => {
      return v.OriginPlace === defaultCountry
    }).map(item => {
      return item.Part
    })
    const uniqueCuts = unique(cuts)
    uniqueCuts.splice(0, 1, '全部')
    return uniqueCuts
  }
}

// 去重函数
export function unique (arr) {
  arr.unshift('全部')
  return Array.from(new Set(arr))
}

// 排序函数
export function sorting (arr, col, type) {
  if (type === '_ups') {
    const desc = function (x, y) {
      return (x[col] < y[col]) ? 1 : -1
    }
    arr.sort(desc)
  } else {
    if (type === '_downs') {
      const asc = function (x, y) {
        return (x[col] > y[col]) ? 1 : -1
      }
      arr.sort(asc)
    }
  }
}
