<template>
  <div class="search-picker-box" @click.stop>
    <div class="search-picker-bar">
      <div class="hand" @click="handleShow">
        <span>{{ name }}</span>
        <div :class="{ 'triangle-up': isShow, 'triangle-down': !isShow }" class="btc-triangle"></div>
      </div>
    </div>
    <div v-if="mode === 'one'" v-show="isShow" class="search-picker-drop-down one-col">
      <div v-for="(item, index) in data" :key="index" class="hand">{{ item }}
        <b-form-checkbox v-model="checkbox" :value="item" :disabled="checkbox.length === 1 && checkbox[0] === item" plain @change="handleChangeCheckbox($event, 'checkbox')"/>
      </div>
    </div>
    <div v-if="mode === 'two' && data !== null && data.length !== 0" v-show="isShow" class="search-picker-drop-down two-col">
      <div v-for="(item, index) in data" :key="index" class="hand">{{ item }}
        <b-form-checkbox v-model="checkbox2" :value="item" plain @change="handleChangeCheckbox($event, 'checkbox2')"/>
      </div>
    </div>
    <div v-if="mode === 'two' && data !== null && data.length === 0" v-show="isShow" class="search-picker-drop-down none-col">
      <div>
        No Data
      </div>
    </div>
    <div v-show="isShow" :style="{ width : mode === 'one' ? '130px' : '360px' }" class="determine">
      <button :disabled="data !== null && data.length === 0" class="btn" @click="handleOk">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CnMultiSelect',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    checkItem: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: '国别'
    },
    mode: {
      type: String,
      default: 'one'
    },
    defaultFeed: {
      type: String,
      default: '草饲'
    },
    defaultType: {
      type: String,
      default: '_spotgoods'
    },
    isShow: {
      type: Boolean,
      default: false
    },
    isRadio: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    defaultFeedVisible: {
      get () {
        return this.defaultFeed
      },
      set (val) {
        this.$emit('update:defaultFeed', val)
      }
    },
    defaultTypeVisible: {
      get () {
        return this.defaultType
      },
      set (val) {
        this.$emit('update:defaultType', val)
      }
    },
    checkItemVisible: {
      get () {
        return this.checkItem
      },
      set (val) {
        this.$emit('update:checkItem', val)
      }
    }
  },
  watch: {
    defaultFeedVisible (val) {
      if (val === '草饲') {
        this.checkbox = ['巴西']
        this.checkbox2 = ['牛腩80vl']
      } else {
        this.checkbox = ['澳大利亚']
        this.checkbox2 = ['板腱']
      }
    },
    defaultTypeVisible (val) {
      this.checkbox = ['巴西']
      this.checkbox2 = ['牛腩80vl']
    },
    checkItemVisible (val) {
      this.checkbox2 = val
    }
  },
  data () {
    return {
      input: '',
      current: -1,
      checkbox: ['西班牙'],
      checkbox2: ['六分体']
    }
  },
  methods: {
    handleChangeCheckbox (e, type) {
      this[type] = e
      if (this.isRadio) {
        if (this[type].length === 2) {
          this[type].shift()
        }
      } else {
        if (this[type].length === 6) {
          this[type].shift()
        }
      }
      this.$emit('handleCheckbox', this[type])
    },
    handleShow () {
      this.$emit('handleShow')
    },
    handleOk () {
      this.$emit('handleOk')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-picker-box {
  font-size: 12px;
  position: relative;
  margin-right: 20px;
}
.search-picker-bar {
  display: flex;
}
.search-picker-bar > div {
  height: 30px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border: 1px solid #5f6162;
  border-radius: 15px;
  font-weight: bold;
}

.search-picker-drop-down {
  background: #ffffff;
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 5px #e0e0e0;
  overflow-y: scroll;
}
.one-col {
  height: 150px;
  width: 130px;
  right: 20%;
  padding: 8px 0;
}
.one-col > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.determine {
  height: 25px;
  background-color: #ffffff;
  position: absolute;
  padding: 4px;
  border-radius: 0 0 10px 10px;
  left: 0;
  top: 190px;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 3px #e0e0e0;
}
.determine > button {
  color: #ffffff;
  width: 40px;
  border-radius: 10px;
  background-color: #014479;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.two-col {
  height: 150px;
  width: 360px;
  right: -40%;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-flow: wrap;
  //padding: 0 10px;
}

.none-col {
  height: 150px;
  width: 360px;
  right: -40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.two-col > div {
  width: 50%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
</style>
